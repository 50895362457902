'use strict';

import ApplicantBlockManageButtonsView from '@js/view/ApplicantBlockManageButtonsView';
import ApplicantFavoriteManageButtonsView from '@js/view/ApplicantFavoriteManageButtonsView';

new class {
    /**
     * コンストラクタ
     */
    constructor() {
        const csrfToken = $('meta[name="csrf-token"]').attr('content');
        $.ajaxSetup({ headers: { 'X-CSRF-TOKEN': csrfToken } });

        // ブロック登録、解除ボタン
        new ApplicantBlockManageButtonsView();

        // お気に入り登録、解除ボタン
        new ApplicantFavoriteManageButtonsView();
    }
}();
