'use strict'

import ApplicantFavorite from '@js/model/ApplicantFavorite';

export default class ApplicantFavoriteManageButtonsView {
    /** @private @constant {string} #MANAGE_BUTTON お気に入り登録ボタンのセレクタ */
    #BUTTONS = '.btn-applicant-favorite-manage';

    /** @private @type {ApplicantFavorite} #applicantFavorite ApplicantFavorite モデル*/
    #applicantFavorite;

    /**
     * コンストラクタ
     */
    constructor() {
        this.#applicantFavorite = new ApplicantFavorite();

        this.#initButtons();
    }

    /**
     * ボタンの初期化
     */
    #initButtons() {
        $(this.#BUTTONS).on('click', (event) => {
            const button = event.currentTarget;
            const $button = $(button);
            $button.prop('disabled', true);

            const mode = $button.data('mode');

            const data = {
                job_id: $button.data('job-id'),
                mode: mode,
            };

            this.#applicantFavorite
                // 成功時のコールバック
                .setOption('afterManage', () => {
                    const nextMode = (mode === 'add') ? 'remove' : 'add';
                    this.#switchButton(button, nextMode);
                    return false;
                })
                // 終了時のコールバック
                .setOption('onManageFinally', () => {
                    $button.prop('disabled', false);
                })
                .manage(data);
        });
    }

    /**
     * ボタンの表示切替
     *
     * @param {HTMLButtonElement} button 対象のボタン
     * @param {string} mode 操作モード
     */
    #switchButton(button, mode) {
        const $button = $(button);
        $button.data('mode', mode);

        // アイコン
        const isIconOnly = typeof $button.data('icon-only') !== 'undefined';
        if (isIconOnly) {
            const fill = mode === 'remove' ? '#FFFF00' : 'none';
            $button.find('path').attr('fill', fill);
        } else {
            const $use = $button.find('use');
            const iconUrl = $use.data(`href-${mode}`);
            $use.attr('href', iconUrl);
        }

        // テキスト
        const $buttonText = $button.find('.text');
        const buttonText = $buttonText.data(`text-${mode}`);
        $buttonText.text(buttonText);
    }
}
