'use strict';

/**
 * ApplicantFavorite モデル
 */
export default class ApplicantFavorite {
    /** @private @constant {string} #MANAGE_URL 保存や削除を行う URL */
    #MANAGE_URL = '/job/favoriteadd';

    /** @private @type {Object} #options オプション */
    #options = {
        afterManage: undefined,
        onManageError: undefined,
        onManageFinally: undefined,
    };

    /**
     * コンストラクタ
     *
     * @param {Object} options オプション
     */
    constructor(options = {}) {
        this.#options = { ...this.#options, ...options };
    }

    /**
     * オプションセット
     *
     * @param {string} name オプション名
     * @param {*} value 値
     * @returns {this}
     */
    setOption(name, value) {
        if (this.#options.hasOwnProperty(name)) {
            this.#options[name] = value;
        }

        return this;
    }

    /**
     * 保存 もしくは 削除
     *
     * @param {(Array|FormData|Object|string|null|undefined)} formData フォームデータ
     */
    manage(formData) {
        const modeNameList = {
            add: '登録',
            remove: '削除',
        };

        const mode = formData.mode;
        const modeName = mode ? modeNameList[mode] : undefined;
        if (typeof modeName === 'undefined') {
            alert(`【エラー】${modeName}ができませんでした`);
            return;
        }

        $.ajax({
                url: this.#MANAGE_URL,
                method: 'POST',
                data: formData,
            })
            .done((response) => {
                const afterManage = this.#options.afterManage;
                if (typeof afterManage === 'function' && afterManage(response) === false) {
                    return;
                }
                alert(`${modeName}が完了しました`);
            })
            .fail((xhr, status, error) => {
                const onManageError = this.#options.onManageError;
                if (typeof onManageError === 'function' && onManageError(xhr, status, error) === false) {
                    return;
                }
                alert(`【エラー】${modeName}ができませんでした`);
            })
            .always(() => {
                const onManageFinally = this.#options.onManageFinally;
                if (typeof onManageFinally === 'function') {
                    onManageFinally();
                }
            });
    }
}
